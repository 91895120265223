.common__section {
    background: linear-gradient(rgba(0,0,0,0.562), rgba(0,0,0,0.562)),url("../assets/images/common-section.jpg") no-repeat center center;
    background-size: cover;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.common__section h1 {
    color: #fff;
    font-weight: 600;
}