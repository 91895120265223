.product__item {
    cursor: pointer;
}

.product__info h3 a {
    font-size: 1.2rem;
    color: var(--primary-color);
    font-weight: 600;
    margin-top: 15px;
}

.product__info a:hover {
    color: inherit;
}

.product__info span {
    font-size: 0.9rem;
}

.product__card-bottom .price {
    color: var(--primary-color);
    font-size: 1.3rem;
    font-weight: 500;   
}

.product__card-bottom span i {
    font-size: 1.2rem;
    padding: 5px;
    background: var(--primary-color);
    color: #fff;
    border-radius: 50px;
}

@media only screen and (max-width:996px) {
    .product__info h3 a {
        font-size: 1.1rem;
    }
}

@media only screen and (max-width:768) {
    .product__info h3 a {
        font-size: 1rem;
    }

    .product__card-bottom span i {
        font-size: 1rem;
    }
}