@media only screen and (max-width:992px) {
  .clock__wrapper {
    justify-content: center;
  }

  .clock__data h1 {
    font-size: 1.1rem !important;
  }

  .clock__data h5 {
    font-size: .9rem !important;
  }
}